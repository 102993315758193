function OptanonWrapper() {

	const gpcEnabled = navigator.globalPrivacyControl;	// Detect if the browser transmits a GPC (Global Privacy Control) signal
	const cookieBannerContainer = document.getElementById('onetrust-consent-sdk');	// Top level OneTrust container

	// Append the Cookie consent container to the DOM
	document.body.prepend(cookieBannerContainer);

	// Display appropriate text based on GPS signal
	if (gpcEnabled) {
		/*
			In the Banner and Privacy Preference Center are two versions of text:
			1. scenario – no GPS signal is detected: display text in the container with the "gpc-off" class
			2. scenario – GPS signal detected: display text in the container with the "gpc-on" class
		*/
		cookieBannerContainer.classList.toggle("gpc-detected");
	}


	//
	//	Privacy Preference Center (<div id="onetrust-pc-sdk">)
	//
	/*
		1. Expands all category descriptions
		2. Relocate "Reject all" button from bottom to the top
	*/
	function setupPrivacyPreferenceCenter() {
		// No previous interaction with Cookie Consent Banner
		if (!OneTrust.IsAlertBoxClosed()) {

			// Preference Center buttons
			const otPcExpandButton = document.querySelectorAll('.ot-accordion-layout button');	// Expand Category description button
			const otPcRejectAllButton = document.querySelectorAll('.ot-pc-footer .ot-pc-refuse-all-handler');	// "Reject all" button
			const otPcAcceptAllButton = document.getElementById('accept-recommended-btn-handler');	// "Accept all" button
	
			// 1. Expand all Categories description
			for (var i = 0; i < otPcExpandButton.length; i++) {
				otPcExpandButton[i].setAttribute('aria-expanded', 'true');
			}
	
			// 2. Relocate "Reject all" button from bottom to the top (after the "Accept all" button)
			if (otPcRejectAllButton.length > 0 && otPcAcceptAllButton) {
				otPcAcceptAllButton.parentNode.insertBefore(otPcRejectAllButton[0], otPcAcceptAllButton.nextSibling);
			}
		}
	}
	setupPrivacyPreferenceCenter();



	//
	//	US Mininalistic banner
	//
	/*
		The function oneTrustMiniBanner() handles following templates
		- Avast – GPC MINIMALISTIC (+ Reject All)
		- Avast – GPC MINIMALISTIC (+ Manage)

		DEV notes
		–––––––––
		- The function oneTrustMiniBanner() creates a new HTML template on-the-fly via JavaScript.
		- Text used in the new template extracted from the original OneTrust banner template (<div id="id="onetrust-banner-sdk">).
		- The original OneTrust banner template is hidden via custom CSS (defined in OneTrust admin)
			- if you need to adjust the custom CSS, open one of the following links, click the "Banner" tab, open "Styling" accordion and locate "Custom CSS" textarea

				1. Template
				Name: Avast – GPC MINIMALISTIC (+ Reject All)
				Link: https://app-de.onetrust.com/cookies/templates/edit/019204a0-3b96-757b-934f-70890d53f323

				2. Template
				Name: Avast – GPC MINIMALISTIC (+ Manage)
				Link: https://app-de.onetrust.com/cookies/templates/edit/01948389-38e6-78cc-b1a5-8f16e7028090

		- the "gpcMinimalisticTemplate" element
			- exists only in the "Avast – GPC MINIMALISTIC (...)" templates
			- is located in OneTrust admin (use links above)
				- go to the "Banner" tab
				- open "Content" accordion item
				- click the "Title & Description"
				- locate the "Notice description" textarea
				- NOTE: OneTrust has limited support for HTML syntax. You can use <span>, <b>, <strong> but not a <div>

	*/

	const gpcMinimalisticTemplate = document.getElementById('oneTrust__GPCminimalisticTemplate--text');

	function oneTrustMiniBanner() {
		if(!gpcEnabled && !OneTrust.IsAlertBoxClosed() && gpcMinimalisticTemplate) {
			console.log('OneTrustMini Banner - v1.0.0'); // Please retain the version number to help us track which version is in production.
	
			// The new HTML template for the Cookie Consent Banner will be stored here.
			var otMini;

			// Helper classes
			const bannerExpandedClass = 'js-expanded';
			const bannerHiddenClass = 'js-hidden';
	
			// Checks if the page is an Academy page (URL starts with www.avast.com/c-)
			const homepageAttr = document.body.getAttribute('data-homepage');
			const isAcademyPage = homepageAttr ? homepageAttr.includes('/c-academy') : false; // Only the Academy pages have <body data-homepage="/c-academy">

			// Checks if template with the "Reject" button is used
			const isGpcMinimalisticRejectAllTemplate = OneTrust.GetDomainData().BannerShowRejectAllButton; // Returns true when the "Reject" button is shown


			//
			//	1. Retrieve data from the OneTrust object
			//
			const otDomainData = OneTrust.GetDomainData();

			const otData = {
				// The text is defined in OneTrust admin (tab "Banner" > "Content" accordion > "Title & Description" > "Notice description" textarea)
				text: gpcMinimalisticTemplate.textContent, // get text "This site uses".
				cookiePolicyLink: {
					// The text is defined in OneTrust admin (tab "Banner" > "Content" accordion > "Button set" > "Other Text" input)
					text: otDomainData.CookieSettingButtonText, // text for the link – "cookies"
					href: otDomainData.AboutLink // link "https://www.avast.com/cookies-policy"
				},
				buttons: {
					// The text is defined in OneTrust admin (tab "Banner" > "Content" accordion > "Button set")
					accept: otDomainData.AlertAllowCookiesText, // text for the Accept button
					reject: otDomainData.BannerRejectAllButtonText, // text for the Reject button
					manage: otDomainData.AlertMoreInfoText // text for the Manage button
				}
			};

			/*
			// Access and log data
			console.log(otData.text);                     // get text "This site uses"
			console.log(otData.cookiePolicyLink.text);    // get text "cookies"
			console.log(otData.cookiePolicyLink.href);    // get text "https://www.avast.com/cookies-policy"
			console.log(otData.buttons.accept);           // button "Accept"
			console.log(otData.buttons.reject);           // button "Reject"
			console.log(otData.buttons.manage);           // button "Manage"
			*/


			//
			// 2. Create OneTrust Mini Banner
			//
			function createOneTrustMiniBanner() {
				//
				// 2.1 Create main container
				//
				otMini = document.createElement('div');
				otMini.id = 'oneTrustMini';
				otMini.tabIndex = 0; // Makes the Banner accessible via keyboard
				
				// Mobile – handle tap on the Cookie icon (Expands the banner)
				otMini.addEventListener('touchend', function (e) {
					// Check if the 'bannerExpandedClass' is already applied
					if (!otMini.classList.contains(bannerExpandedClass)) {
						e.preventDefault(); // Prevent default only if the banner is not expanded (without this, the 'Reject'/'Manage' button was activated when the banner expanded)
						otMini.classList.add(bannerExpandedClass); // Expand the banner
					}
				});

				//
				// 2.2 Create Cookie icon
				//
				const cookieIcon = document.createElement('div');
				cookieIcon.id = 'oneTrustMini__cookie-icon';
				otMini.appendChild(cookieIcon);
	
				//
				// 2.3 Create Content container
				//
				const contentContainer = document.createElement('div');
				contentContainer.id = 'oneTrustMini__content';
				otMini.appendChild(contentContainer);
	
				//
				// 2.3.1 Create Policy Text container
				//
				const policyText = document.createElement('div');
				policyText.id = 'oneTrustMini__text';
	
				// Create the span element for the text
				const span = document.createElement('span');
				span.textContent = otData.text + ' '; // text "This site uses" + add space character after the text
	
				// Create Cookies policy link
				const link = document.createElement('a');
				link.href = otData.cookiePolicyLink.href;
				link.target = '_blank';
				link.id = 'oneTrustMini__policy-link';
				link.textContent = otData.cookiePolicyLink.text; // link text - "cookies"
	
				// Append content to the Text container
				policyText.appendChild(span);
				policyText.appendChild(link);
	
				//
				// 2.3.2 Create Buttons container
				//
				const buttonsDiv = document.createElement('div');
				buttonsDiv.id = 'oneTrustMini__buttons';
	
				// Create Accept button
				const acceptButton = document.createElement('button');
				acceptButton.id = 'oneTrustMini--accept';
				acceptButton.textContent = otData.buttons.accept;
	
				// Handle click on Accept button
				acceptButton.addEventListener('click', function(){
					OneTrust.AllowAll(); // Call OneTrust SDK function
					otMini.classList.add(bannerHiddenClass); // Hide the Banner
				});


				// Create secondary button (Reject / Manage)
				const secondaryButton = document.createElement('button');

				if(isGpcMinimalisticRejectAllTemplate) {
					// Create the "Reject" button (California only)
					secondaryButton.textContent = otData.buttons.reject;

					// Handle click on the Reject button
					secondaryButton.addEventListener('click', function(){
						OneTrust.RejectAll(); // Call OneTrust SDK function
						otMini.classList.add(bannerHiddenClass); // Hide the Banner
					});
				} else {
					// Create the "Manage" button for rest of the US states
					secondaryButton.textContent = otData.buttons.manage;
						
					// Handle click on the Manage button
					secondaryButton.addEventListener('click', function(){
						OneTrust.ToggleInfoDisplay(); // Opens the OneTrust Preference Center
					});
				}

				// Append buttons to the Buttons container
				buttonsDiv.appendChild(acceptButton);
				buttonsDiv.appendChild(secondaryButton);
	
				//
				// 2.3 Append content to the Content container
				//
				contentContainer.appendChild(policyText);
				contentContainer.appendChild(buttonsDiv);
	
				//
				// 2.4 Append the whole Banner to the body
				//
				document.body.appendChild(otMini);

				//
				// 2.5 Preference center – hide the Banner when "Accept all" or "Confirm selection" button is clicked in the Preference center
				//
				const otPcAcceptAllButton = document.getElementById('accept-recommended-btn-handler');	// "Accept all" button
				const otPcConfirmSelectionButton = document.querySelector('.save-preference-btn-handler');	// "Confirm selection" button

				// Handle click on "Accept all" button
				otPcAcceptAllButton.addEventListener('click', function() {
					otMini.classList.add(bannerHiddenClass); // Hide the Banner
				});

				// Handle click on "Confirm selection" button
				otPcConfirmSelectionButton.addEventListener('click', function() {
					otMini.classList.add(bannerHiddenClass); // Hide the Banner
				});
			}
			createOneTrustMiniBanner();
	
	
			//
			//	3. Expand/Collapse OneTrust Mini banner on scroll
			//
			function toggleBannerOnScroll() {
				window.addEventListener('scroll', function () {
					// Get the scroll position
					const scrollPosition = window.scrollY || document.documentElement.scrollTop;
	
					// Expand/Collapse the Banner when scrolled more then 200px from the top
					if (scrollPosition > 200) {
						if (isAcademyPage) {
							// Collapse Banner on Academy pages
							otMini.classList.remove(bannerExpandedClass);
						} else {
							// Expand Banner on all other pages
							otMini.classList.add(bannerExpandedClass);
						}
					}
				});
			}
			toggleBannerOnScroll();


			//
			//	4. Academy pages
			//
			function academyPagesBanner() {
				if (isAcademyPage) {
					// Banner is open by default (and collapsed on scroll)
					otMini.classList.add(bannerExpandedClass);	// expand the Banner

					// Academy pages have a Sticky Bar. Move Cookie Consent Banner above the Sticky Bar
					function adjustCookieConsentBannerPositionOnAcademyPages() {
						const bodyClasses = document.body.className.split(' ');
					
						// Extract platform class from <body> (for example "os-android")
						function getPlatformClass(classes) {
							for (var i = 0; i < classes.length; i++) {
								if (classes[i].startsWith('os-')) {
									return classes[i];
								}
							}
							return null;
						}
					
						const platformClass = getPlatformClass(bodyClasses);

						//
						//	Get reference to Sticky bars
						//
						/*
							Academy pages are using 3 different sticky bars:
							1. mobileStickyBar – used on Article detail template (example: https://www.avast.com/c-spear-phishing)
							2. desktopStickyBar - used on Article detail template
							3. toolPageStickyBar - used on Tool page template (example: https://www.avast.com/c-virus-removal-tool)
						*/
						const mobileStickyBar = document.querySelector('.sticky-post-widget');
						// Get the Sticky bar currently in use (a bar exists for each platform; retrieve the appropriate one)
						const desktopStickyBar = platformClass ? document.querySelector('.os-detect.' + platformClass + ' .sidebar-widget-transformable') : null;
						const toolPageStickyBar = platformClass ? document.querySelector('.os-detect.' + platformClass + ' .sticky-widget') : null;

						// Adjust position of the Cookie Consent Banner
						function adjustCookieConsentBannerMargin(stickyBar, isVisible) {
							// when the Sticky Bar is visible, get its height and set it as margin to the Cookie Consent Banner.
							// when the Sticky Bar NOT visible, reset margin to 0px
							const height = isVisible ? stickyBar.offsetHeight : 0;
							otMini.style.marginBottom = height + 'px';
						}
					
						function checkMobileStickyBarVisibility() {
							// check if the stickyBar exists and it's not hidden via CSS
							const isVisible = mobileStickyBar && window.getComputedStyle(mobileStickyBar).display !== 'none';
							adjustCookieConsentBannerMargin(mobileStickyBar, isVisible);
						}
					
						function checkDesktopStickyBarVisibility() {
							if (!desktopStickyBar) return;	// check if the stickyBar exists
							const style = window.getComputedStyle(desktopStickyBar);
							// this unique combinations indicates that the stickyBar is sticked to the bottom of the screen and is visible
							const isVisible = style.display === 'flex' && style.position === 'fixed';
							adjustCookieConsentBannerMargin(desktopStickyBar, isVisible);
						}

						function checkToolPageStickyBarVisibility() {
							if (!toolPageStickyBar) return;	// check if the stickyBar exists
							const style = window.getComputedStyle(toolPageStickyBar);
							// this unique combinations indicates that the stickyBar is sticked to the bottom of the screen and is visible
							const isVisible = style.display === 'block' && style.bottom === '0px';
							adjustCookieConsentBannerMargin(toolPageStickyBar, isVisible);
						}

						// Only one Sticky Bar is visible at a time. Call the appropriate function.
						function checkStickyBarVisibility() {
							const viewportWidth = window.innerWidth;
					
							if (viewportWidth < 1056) {
								checkMobileStickyBarVisibility();
								checkToolPageStickyBarVisibility();
							} else {
								checkDesktopStickyBarVisibility();
							}
						}
					
						// Initial visibility check
						checkStickyBarVisibility();
					
						// Re-check visibility on scroll and window resize
						window.addEventListener('scroll', checkStickyBarVisibility);
						window.addEventListener('resize', checkStickyBarVisibility);
					}
					adjustCookieConsentBannerPositionOnAcademyPages();
				}
			}
			academyPagesBanner();
		}
	}
	oneTrustMiniBanner();


	//
	//	Relocate "OK" button to the buttons container
	//
	/*
		The function relocateOKButton() handles following templates:
		- Avast – GPC UNOBTRUSIVE (+ Reject All) (MVP – California)
		- Avast – GPC UNOBTRUSIVE (MVP)
	*/
	function relocateOKButton() {
		var gpcOKButton = document.getElementById("cookieGPCControlButton"), // "OK" button (in fact, this is an <a id="cookieGPCControlButton"> element). It is stored in <p id="onetrust-policy-text"> (text and HTML is defined in OneTrust admin).
			buttonContainer = document.querySelector(".banner-actions-container") // Button container (for the "Reject all", "Manage...")

		if (gpcOKButton) {
			// Relocate the "OK" button (move it to the buttonContainer)
			buttonContainer.appendChild(gpcOKButton);

			// GPC signal is ON
			if (gpcEnabled) {
				gpcOKButton.addEventListener('click', function() {
					OneTrust.Close(); // This will "Confirm" default settings set by the GPS signal (user can predefine his default settings in his browser. GPS signal then "shares" this settings with the Cookie Consent).

					// Cookie consent was closed. Re-enable scroll on the page (used on V2 pages)
					document.body.classList.remove('ot-overflow-hidden');
				});
			}
			// GPC signal is OFF (user's browser doesn't support GPS signal)
			else {
				gpcOKButton.addEventListener('click', function() {
					OneTrust.AllowAll(); // Accept cookies and hides the OneTrust banner and overlay

					// Cookie consent was closed. Re-enable scroll on the page (used on V2 pages)
					document.body.classList.remove('ot-overflow-hidden');
				});
			}
		}
	}
	relocateOKButton();
}
